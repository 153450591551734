<template>
  <div>
    <v-card class="pa-4" max-width="720" min-height="300">
      <v-card-title class="mb-3 panel-title primary--text" v-if="planModel._id">
        Edit Plan
        <!-- <span class="ml-2 font-weight-light">{{ planModel.email }}</span> -->
      </v-card-title>
      <v-card-title class="mb-3 panel-title primary--text" v-else>
        Add Plan
      </v-card-title>
      <v-card-text v-if="planModel">
        <v-form
          ref="profileForm"
          v-model="valid"
          @submit.prevent="submit"
          action
        >
          <v-row>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                autofocus
                dense
                label="Name"
                v-model="planModel.name"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="name"
              ></v-text-field>

              <v-text-field
                label="Amount"
                dense
                v-model="planModel.amount"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-select
                :items="schedule"
                dense
                label="Schedule"
                v-model="planModel.schedule"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="name"
              ></v-select>

              <v-text-field
                label="Down Payment"
                dense
                v-model="planModel.downPayment"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                label="Recurring Amount"
                dense
                v-model="planModel.recurringAmount"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="number"
              ></v-text-field>

              <v-text-field
                label="Recurring Cycle (Days)"
                dense
                v-model="planModel.recurringCycle"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-select
                :items="recurrThreshold"
                dense
                label="Recurring Threshold"
                v-model="planModel.recurrThreshold"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
              ></v-select>

              <v-switch
                v-model="planModel.enabled"
                :label="`${planModel.enabled ? 'Enabled' : 'Disabled'}`"
                color="orange"
              ></v-switch>
            </v-col>
          </v-row>
          <!-- <v-divider class="mb-2 mt-n4"></v-divider> -->

          <v-row dense class="align-center justify-space-between">
            <v-col cols="12" xs="12" sm="4" class="mr-6 mt-4">
              <v-btn @click="$emit('close-dialog')" outlined block>Close</v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="mt-4">
              <v-btn :loading="loading" color="primary" @click="submit" block
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
        <!-- <v-row></v-row>
        <v-row class="mt-6 justify-end">
          <v-btn @click="$emit('close-dialog')" large raised color="primary"
            >Save</v-btn
          >
        </v-row> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "../../utils/config";
export default {
  computed: {
    ...mapGetters(["formatCurrency", "formatDateMed"]),
  },
  created() {
    for (let index = 1; index < 20; index++) {
      this.recurrThreshold.push(index.toString());
    }
  },
  methods: {
    ...mapActions("api", ["httpGet", "httpPost", "httpPatch", "httpDelete"]),
    ...mapActions(["showMessage"]),
    save(date) {
      this.$refs.menu.save(date);
    },

    validate: function () {
      this.$refs.profileForm.validate();
    },
    saveData: async function () {
      console.log("creating===", this.planModel);

      let params = await this.httpPost({
        withAuth: true,
        url: "/plan",
        data: this.planModel,
      });
      return params.data;
    },
    updateData: async function () {
      console.log("updating===", this.planModel);

      let params = await this.httpPatch({
        withAuth: true,
        url: "/plan",
        data: this.planModel,
      });
      return params.data;
    },
    submit: function () {
      this.validate();
      this.loading = true;
      setTimeout(async () => {
        if (!this.valid) {
          this.showMessage({
            text: "Please fix issues with form data",
            error: true,
          });
          this.loading = false;
        } else {
          try {
            if (this.planModel._id) {
              await this.updateData();
            } else {
              await this.saveData();
            }
            this.loading = false;
            this.showMessage({ text: "Plan saved", error: false });
            this.$emit("refresh");
          } catch (error) {
            this.loading = false;
            let errMsg = config.errors.generic;
            this.showMessage({
              text: errMsg,
              error: true,
            });
          }
        }
      }, 100);
    },
  },
  data() {
    return {
      search: "",
      planModel: { enabled: true },
      menu: false,
      loading: false,
      busy: false,
      nameRules: [(v) => !!v || "This Field is required"],
      schedule: ["Monthly", "Weekly"],
      recurrThreshold: [],
      valid: false,
    };
  },
};
</script>
<style lang="css" scoped>
.panel-title {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  font-size: 20px;
}
.text-row {
  width: 280px;
  margin-bottom: 10px;
}
.text-title {
  font-family: "Poppins", "sans-serif";
  font-weight: 500;
  color: #777;
  margin-right: 10px;
}
.text-bd {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  color: #555;
  font-size: 14px;
}
</style>

