var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"pa-4",attrs:{"shaped":""}},[_c('v-card-title',[_vm._v("Plans")]),_c('v-btn',{staticClass:"ml-4",attrs:{"fixed":"","bottom":"","right":"","fab":"","small":"","color":"primary","rounded":""},on:{"click":_vm.addPlan}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.header,"search":_vm.search,"items":_vm.transactions,"server-items-length":_vm.count,"loading":_vm.loading},on:{"update:options":_vm.getOptions},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"color":"light-green darken-3","pill":""}},[_vm._v(" "+_vm._s(_vm.formatCurrencyDecimal(item.amount, 2, true))+" ")])]}},{key:"item.downPayment",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"color":"orange darken-3","pill":""}},[_vm._v(" "+_vm._s(_vm.formatCurrencyDecimal(item.downPayment, 2, true))+" ")])]}},{key:"item.recurringAmount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"color":"teal darken-3","pill":""}},[_vm._v(" "+_vm._s(_vm.formatCurrencyDecimal(item.recurringAmount, 2, true))+" ")])]}},{key:"item.recurringCycle",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"color":"grey darken-3","pill":""}},[_vm._v(" "+_vm._s(item.recurringCycle)+" ")])]}},{key:"item.recurrThreshold",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"color":"grey darken-3","pill":""}},[_vm._v(" "+_vm._s(item.recurrThreshold)+" ")])]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"color":"grey darken-3","pill":""}},[(item.enabled)?_c('v-icon',{attrs:{"color":"light-green"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"deep-orange"}},[_vm._v("mdi-close")])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-16",attrs:{"color":"red"},on:{"click":function($event){return _vm.openWarn(item)}}},[_vm._v("mdi-delete")]),_c('v-icon',{staticClass:"mr-0",attrs:{"color":"primary"},on:{"click":function($event){return _vm.showDetail(item)}}},[_vm._v("mdi-open-in-new")])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"520"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('PlansEdit',_vm._g({ref:"dialog"},_vm.handlers))],1),_c('v-dialog',{attrs:{"max-width":"280"},model:{value:(_vm.warnDlg),callback:function ($$v) {_vm.warnDlg=$$v},expression:"warnDlg"}},[_c('v-card',{staticClass:"pa-6"},[_c('v-card-text',[_c('v-row',[_c('div',{staticClass:"title"},[_vm._v("Remove Plan?")])]),_c('v-row',[_c('div',{staticClass:"caption mt-2 mb-4"},[_vm._v(" This cannot be reversed! ")])]),_c('v-row',{staticClass:"justify-space-between"},[_c('v-btn',{attrs:{"outlined":"","color":""},on:{"click":function($event){_vm.warnDlg = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"dark":"","loading":_vm.loading,"color":"red"},on:{"click":_vm.remove}},[_vm._v("Remove")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }